// Custom global scripts
jQuery(document).ready(function($) {
    'use strict';

    // Foundation Init
    //$(document).foundation();

    // Mobile nav toggle
    $('.nav-mobile-toggle').on('click', function() {
    	$(this).toggleClass('is-active');
        $('.nav-main-wrap').slideToggle();
    });

    // Setup Parallax
    //var rellax = new Rellax('.rellax');

    // Section waypoint
    var waypoints = $('.waypoint');

	waypoints.waypoint(function(direction) {
	  	if (direction === 'down') {
	    	let section = this.element,
	    		images = section.querySelectorAll('img'),
	    		checkmarks = $('ul.checkmarks');

	    	loadBGImg(section);
	    	loadImages(images);
		  	section.classList.add('visible'); 
	  	}
	}, {
	  	offset: '100%'
	});

	var animatedObjs = $('.animate');
	animatedObjs.waypoint(function(direction) {
	  	if (direction === 'down') {
	    	let animation = this.element.getAttribute('data-animation');

		  	this.element.classList.add(animation); 
		  	this.element.classList.add('is-animated'); 
	  	}
	}, {
	  	offset: '80%'
	});
 
 	// Load BG Image
	function loadBGImg(e) {
		let bg = e.getAttribute('data-bg-img');
		if(bg && (e.style.backgroundImage === '' || e.style.backgroundImage === null || e.style.backgroundImage === undefined) ) {
			e.style.backgroundImage = "url(" + bg + ")";
		}
	}

	// Load Images
	function loadImages(images) {
		for(let i = 0; i < images.length; i++) {
			let srcset = images[i].getAttribute('srcset'),
				datasrc = images[i].getAttribute('data-src'),
				datasrcset = images[i].getAttribute('data-srcset');

			if(srcset === '' || srcset === null || srcset === undefined) {
				if(datasrcset !== '' && datasrcset !== null && datasrcset !== undefined) {
					images[i].setAttribute("srcset", datasrcset);
				}
				if(datasrc !== '' && datasrc !== null && datasrc !== undefined) {
					images[i].setAttribute("src", datasrc);
				}
				images[i].classList.remove("invisible");
			} 
		}
	}

	// Accordions
	$('.m-accordion__toggle').on('click', function() {
		let accordion = $(this).parents('.m-accordion'),
			parent = $(this).parents('.m-accordion__item'),
			index = parent.data('num'),
			images = accordion.find('.m-accordion__image'),
			image = accordion.find('.m-accordion__image[data-num="' + index + '"]');

		if(parent.hasClass('active')) {
			//parent.removeClass('active');
		} else {
			parent.siblings().removeClass('active');
			parent.addClass('active');
			images.removeClass('active');
			image.addClass('active');
		}
	});

	// Tabs
	$('.m-tab__toggle--desktop').on('click', function(e) {
		e.preventDefault();

		let tabs = $(this).parents('.m-tabs'),
			//parent = $(this).parent(),
			index = $(this).attr('data-num'),
			contents = tabs.find('.m-tab__content'),
			content = tabs.find('.m-tab__content[data-num="' + index + '"]');

		if($(this).hasClass('active')) {
			//parent.removeClass('active');
		} else {
			$(this).siblings().removeClass('active');
			$(this).addClass('active');
			contents.removeClass('active');
			content.addClass('active');
		}
	});

	// Tabs Mobile
	$('.m-tab__toggle--mobile').on('click', function(e) {
		e.preventDefault();

		let parent = $(this).parents('.m-tab__contents'),
			siblings = parent.find('.m-tab__toggle--mobile'),
			//parent = $(this).parent(),
			index = $(this).attr('data-num'),
			contents = parent.find('.m-tab__content'),
			content = parent.find('.m-tab__content[data-num="' + index + '"]');

		if($(this).hasClass('active')) {
			$(this).removeClass('active');
			content.removeClass('active');
		} else {
			//siblings.removeClass('active');
			//contents.removeClass('active');
			$(this).addClass('active');
			content.addClass('active');
		}
	});

	// Category Select
	$(".m-cat-select select").selectedIndex = 0;
	$('.m-cat-select select').change(function() {
		let value = $(this).find(":selected").val(),
			text = $(this).find(":selected").text(),
			parent = $(this).parents('.m-cat-select'),
			count_container = parent.find('.m-post-count'),
			count_message = '',
			posts = $('.m-sortable-post'),
			count = 0;

		parent.addClass('loading');

		posts.each(function() {
			$(this).addClass('loading');
		});

		setTimeout(function() {
			parent.removeClass('loading');

			posts.each(function() {
				$(this).removeClass('loading');
			}); 
		}, 700);

		if(parent.hasClass('m-cat-select--testimonial-sort')) {
			sortPosts($('.m-sortable-post.active'), $('.m-sortable-post.active.' + value + ''), value, 'm-quote--');
		} else { 
			count = filterPosts(posts, value === '' || value === 'all' ? 'all' : value);

			if(parent.hasClass('m-cat-select--case-studies')) { 
				let active_posts = $('.m-sortable-post.active');
				updatePostOddEven(active_posts, 'm-case-study--');
				count_message = 'Showing ' + count + ' ' + (value === '' || value === 'all' ? '' : text) + ' ' + (count == 1 ? 'Case Study' : 'Case Studies') + ''; 
			} else if(parent.hasClass('m-cat-select--posts')) {
				let active_posts = $('.m-sortable-post.active');
				updatePostOddEven(active_posts, 'm-post--');
				count_message = 'Showing ' + count + ' ' + (value === '' || value === 'all' ? '' : text) + ' ' + (count == 1 ? 'Post' : 'Posts') + ''; 
			} else if(parent.hasClass('m-cat-select--clients')) {
				count_message = 'Showing ' + count + ' ' + (value === '' || value === 'all' ? '' : text) + ' ' + (count == 1 ? 'Client' : 'Clients') + ''; 
			} else if(parent.hasClass('m-cat-select--testimonials')) {
				let sortVal = $(this).parents('.grid-x').find(".m-cat-select--testimonial-sort select option:selected").val();

				sortPosts($('.m-sortable-post.active'), $('.m-sortable-post.active.' + sortVal + ''), sortVal, 'm-quote--');

				count_message = 'Showing ' + count + ' ' + (value === '' || value === 'all' ? '' : text) + ' ' + (count == 1 ? 'Testimonial' : 'Testimonials') + ''; 
			}

			count_container.text(count_message);
			Waypoint.refreshAll();
		}
	});

	// Sort Posts
	function sortPosts(posts, matchingPosts, cat, prefix) {
		let count = 1,
			after = posts.length == 1 ? 1 : matchingPosts.length + 1,
			orderNum = 0; 

		posts.each(function( index ) {
			let post = $(this);

			post.addClass('is-animated');
			post.addClass('visible');
			post.addClass('fadeInUp');

			if(cat === 'date') {
				$(this).css('order', count);
				orderNum = count;
				count++;
			} else {
				if(post.hasClass(cat)) {
					$(this).css('order', count);
					orderNum = count;
					count++;
				} else {
					$(this).css('order', after);
					orderNum = after;
					after++;
				}
			}

			if(orderNum % 2 == 0) {
				post.addClass(prefix + 'even');
				post.removeClass(prefix + 'odd');
			} else {
				post.addClass(prefix + 'odd');
				post.removeClass(prefix + 'even');
			}
		});

		return count++;
	}

	// Filter Posts
	function filterPosts(posts, cat) {
		let count = 0;

		posts.each(function( index ) {
			let post = $(this);

			if(cat === 'all') {
				post.addClass('active');
				post.removeClass('hidden');
				count++;
			} else {
				if(post.hasClass(cat)) {
					post.addClass('active');
					post.removeClass('hidden');
					count++;
				} else {
					post.removeClass('active');
					post.addClass('hidden');
				}
			}
		});

		return count++;
	}

	// Update Posts Odd/Even
	function updatePostOddEven(posts, prefix) {
		posts.each(function( index ) {
			let post = $(this);

			if(index % 2 == 0) {
				post.addClass(prefix + 'odd');
				post.removeClass(prefix + 'even');

				if(prefix === 'm-case-study--') {
					post.attr('data-animation', 'fadeInLeft');
				}
			} else {
				post.addClass(prefix + 'even');
				post.removeClass(prefix + 'odd');

				if(prefix === 'm-case-study--') {
					post.attr('data-animation', 'fadeInRight');
				}
			}
		});
	}

	// Setup Tooltips
	$('.m-tooltip--mobile').tooltipster({
	    animation: 'fade',
	    trigger: "custom",
	    interactive: true,
	    maxWidth: 300,
	    triggerOpen: {
	      	click: true,  // For mouse
	      	tap: true    // For touch device
	    },
	    triggerClose: {
	      	click: true,  // For mouse
	      	tap: true    // For touch device
	    }
	});

	// Setup Post Carousel
	$('.m-posts__carousel')
		.on('afterChange init', function(event, slick, direction){
		    let slidesLength = slick.$slides.length,
		    	activeSlides = [],
		    	css = 'prev-slide';

		    // find current slide
		    for (var i = 0; i < slick.$slides.length; i++) {
		        var $slide = $(slick.$slides[i]);
		        $slide.removeClass('prev-slide').removeClass('next-slide');

		        if ($slide.hasClass('slick-active')) {
		        	activeSlides.push($slide);
		        	$slide.removeClass('prev-slide').removeClass('next-slide');
		        	css = 'next-slide';
		        } else {
		        	$slide.addClass(css);
		        }
		    }
		  }
		)
		.on('beforeChange', function(event, slick, currentSlide, nextSlide) {		    
		    let next = slick.$slides[nextSlide],
		    	current = slick.$slides[currentSlide];

		    if(currentSlide > nextSlide) {
		    	if(slick.$slides[currentSlide + 1] && slick.$slides[currentSlide + 1].classList.contains('slick-active')) {
		    		slick.$slides[currentSlide + 1].classList.add('next-slide');
		    	} else {
		    		current.classList.add('next-slide');
		    	}
		    } else {
		    	current.classList.add('prev-slide');
		    }

		    if(next.classList.contains('slick-active')) {
		    	next = next.nextSibling;
		    }
		    next.classList.remove('prev-slide');
		    next.classList.remove('next-slide');
		})
		.slick({
	        infinite: false,
			slidesToShow: 2,
			slidesToScroll: 1,
			arrows: true,
			dots: false,
			responsive: [
		    	{
		      		breakpoint: 768,
		      		dots: true,
		      		arrows: false,
		      		settings: {
				        slidesToShow: 1,
				        slidesToScroll: 1 
		      		}
		    	}
		  	]
	    });

    // Scroll Buttons
    $('.m-button--scroll').click( function(e) {
    	e.preventDefault(); 

    	let scrollTarget = $(this).parents('.m-section').next('.m-section').find('.m-component--1');

    	$('html, body').animate({
            scrollTop: scrollTarget.offset().top - 60
        }, 400);
    });

    // Video Buttons
    $('.m-button--video').click( function(e) {
    	e.preventDefault(); 

    	let videoId = $(this).attr('href');

    	if(videoId) {
    		let html = `
					<a class="m-popup__close-btn js-close" href="#close">+</a>
					<div class="m-popup__overlay js-close"></div>
					<div class="m-popup__content u-white">
						<div class="m-popup__content__inner">
							<div class="m-embed-container"><iframe src="https://player.vimeo.com/video/${videoId}?autoplay=1&autopause=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>
							</div>
						</div>
					</div>
				`; 

			var popup = document.createElement('div');
			popup.className = "m-popup m-popup--video active";
			popup.innerHTML = html;
			document.body.appendChild(popup);
    	}
    });  

    // Bio Buttons
    $('.js-read-bio').click( function(e) {
    	e.preventDefault(); 

    	let info = JSON.parse($(this).attr('data-bio-info'));

		let html = `
				<a class="m-popup__close-btn js-close" href="#close">+</a>
				<div class="m-popup__overlay js-close"></div>
				<div class="m-popup__wrapper scrollable">
					<div class="m-popup__content u-white">
						<div class="m-popup__content__inner grid-x grid-margin-x align-justify align-middle scrollable">`;
		 		if(info.quote) {
			       html += `<div class="m-quote m-quote--buttons-right white m-quote--small medium-6">
								<blockquote> 
									<p>${info.quote}</p>
									<cite>${info.name} <span>${info.position}</span></cite>`;

									if(info.linkedin) {
										html += `<a href="${info.linkedin}" target="_blank" class="m-button m-button--linkedin m-button--o-yellow-white">CONNECT W/ ${info.first_name}</a>`;
									}
					
						html += `</blockquote>
							</div>`;
						}
				   html += `<div class="m-bio ${(info.quote ? 'medium-6' : 'medium-12')}">${info.bio}</div>
						</div>
					</div>
				</div>`; 

		var popup = document.createElement('div');
		popup.className = "m-popup m-popup--team active";
		popup.innerHTML = html;
		document.body.appendChild(popup);

		const ps = new PerfectScrollbar('.scrollable');
    });  

    // Client Post Popups
    $('.js-client-posts').click( function(e) {
    	e.preventDefault(); 

    	let posts = JSON.parse(decodeURIComponent($(this).attr('data-posts'))),
    		name = $(this).attr('data-name'),
    		grid = 2;

    	if(posts.length === 3) {
    		grid = 3;
    	} else if(posts.length === 1) {
    		grid = 1;
    	}

		let html = `
				<a class="m-popup__close-btn js-close" href="#close">+</a>
				<div class="m-popup__overlay js-close"></div>
				<div class="m-popup__wrapper scrollable">
					<div class="m-popup__content u-white">
						<div class="m-popup__content__inner grid-x grid-margin-x align-justify align-middle scrollable">
							<div style="width: 100%; text-align: center;"><h4>${name} ${(posts.length > 1 ? 'Case Studies' : 'Case Study')}</h4></div>
							<div class="m-component m-posts m-posts__grid m-posts__grid--${grid}">`;
								if(posts.length > 0) {
									posts.forEach(p => {
			 			   html += `<div class="m-post-box" style="background-image: url(${p.img});">
	                            		<div class="m-post-box__content">
	                                		<h2 class="white"><a class="white" href="${p.url}">${p.title}</a></h2>
	                                		<a class="m-button m-button--lg m-button--teal" href="${p.url}">READ MORE</a>
	                        			</div>
	                        		</div>`;
                        			});
								}
		 		   html += `</div>
		 		   		</div>
					</div>
				</div>`; 

		var popup = document.createElement('div');
		popup.className = "m-popup m-popup--client-posts active";
		popup.innerHTML = html;
		document.body.appendChild(popup);

		const ps = new PerfectScrollbar('.scrollable');
    });  

    /**
	 * Close Popup
	 */
	$(document).on('click', '.m-popup .js-close', function(e) {
		e.preventDefault();
 		
		$(this).parents('.m-popup').remove();
	});

	/**
	 * Toggle Bubble Contact
	 */
	$(document).on('click', '.m-contact-bubble a', function(e) {
		if(!$(this).hasClass('has-phone')) {
			e.preventDefault();
		}
		let parent = $(this).parents('.m-contact-bubble');

		parent.toggleClass('loading');

		if($(this).hasClass('js-toggle')) {
			if( !parent.hasClass('expanded') ) {
				parent.addClass('step-2');
			}
	 		setCookie('contactBubbleStatus', 'minimized', 30);
	 		parent.removeClass('step-2');
			parent.toggleClass('expanded');
		} else if ($(this).hasClass('toggle-form')) {
			parent.addClass('step-2');
			parent.addClass('expanded');
		}

		setTimeout(function(){
			parent.toggleClass('loading');
		}, 300);
	});

	/**
	 * Jumbo SVGs
	 */
	function animateJumboSVGs() {
		$('.m-jumbo--animated').each(function(e) {
			let jumbo = $(this),
				svgs = $(this).find('svg'),
				length1 = 100,
				wait2 = svgs.length * length1,
				length2 = 50,
				overall = wait2 + (length2 * svgs.length) + 1000;

			svgs.each(function(index) {
				let svg = $(this);
				setTimeout(function() {
					svg.addClass('step-1');
				}, index * length1);
			}); 

			setTimeout(function() {
				svgs.each(function(index) {
					let svg = $(this);
					setTimeout(function() {
						svg.addClass('step-2');
					}, index * length2);
				});
			}, wait2);

			setTimeout(function() {
				jumbo.parents('.m-section').removeClass('hide-components');
			}, overall);
		});
	}
	animateJumboSVGs();

	function sizeJumboSVGs() {
		$('.m-jumbo').each(function() {
			$(this).find('svg').each(function() {
				let text = $(this).find('.s-text text'),
					width = text[0].getBBox().width,
					height = text[0].getBBox().height;

				$(this).height(height);
				$(this).width(width);
			});
			$(this).removeClass('not-visible');
		});
	}
	setTimeout(function() {
		sizeJumboSVGs();
	}, 500);

	$(window).resize(function() {
  		sizeJumboSVGs();
	});

	/**
	 * Checkmark List
	 */
	$('ul.checkmarks').each(function() {
		let delay = .5;

		$(this).find('li').each(function() {
			$(this).css('animation-delay', delay + 's');
			delay = delay + .5;

			$(this).append('<svg class="m-checkmark-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 90.26"><defs></defs><polyline class="m-checkmark-icon__line-1" style="animation-delay:' + delay + 's" points="88.01 31.74 88.01 87.48 3.31 87.48 3.31 2.78 88.01 2.78 88.01 6.58"/><polyline class="m-checkmark-icon__line-2" style="animation-delay:' + delay + 's" points="23.12 36.49 47.02 60.39 97.62 9.79"/></svg>');
			delay = delay + .5;
		});
	});

	/**
	 * Back To Top
	 */
	$('.m-button--back-to-top').on('click', function() {
		$("html, body").animate({ scrollTop: 0 }, "slow");
	});

	/**
	 * Back To Display
	 */
	var lastScrollTop = 0;
	$(window).scroll(function(event){
		var st = $(this).scrollTop();
		if (st > lastScrollTop || st < 400){
	   		$('.m-button--back-to-top').removeClass('active');
		} else {
			$('.m-button--back-to-top').addClass('active');
		}
	    lastScrollTop = st;
	});

	/**
	 * Trigger video via url parameter
	 */
	let queryString = window.location.search,
		urlParams = new URLSearchParams(queryString),
		play = urlParams.get('play');

	if(play) {
		$('.m-hero .m-button--video').trigger('click');
	}

	/**
	 * Scroll functions
	 */
	$(window).scroll(function() {    
		setHeaderPosition();
		showHideContactBubble();
	});

	/**
	 * Sticky Header
	 */
	setHeaderPosition();

	function setHeaderPosition() {
		var height = $(window).scrollTop();
		if(height  > 60) {
			$(".site-header").addClass("sticky");
		} else{
			$(".site-header").removeClass("sticky");
		}
	}

	/**
	 * Hide contact bubble when scrolled to the bottom of the page
	 */
	showHideContactBubble();

	function showHideContactBubble() {
		let offset = $('.footer-contact') ? $('.footer-contact').offset() : $('.site-footer').offset(),
			scrollOffset = $(window).scrollTop();
			
			if(offset) {
				if(scrollOffset + 600 > offset.top) {
					$(".m-contact-bubble").addClass("hidden");
				} else{
					$(".m-contact-bubble").removeClass("hidden");
				}
			}
	}

	/**
	 * Toggle contact bubble based on cookie
	 */
	checkContactBubbleStatus();
	
	function checkContactBubbleStatus() {
		let status = getCookie('contactBubbleStatus');

		if(status && status == 'minimized') {
			$('.m-contact-bubble').removeClass('expanded');
		} else {
			$('.m-contact-bubble').addClass('expanded');
		}

		$('.m-contact-bubble').removeClass('hidden');
	}

	/**
	 * Manage cookies
	 */
	function setCookie(name,value,days) {
	    var expires = "";
	    if (days) {
	        var date = new Date();
	        date.setTime(date.getTime() + (days*24*60*60*1000));
	        expires = "; expires=" + date.toUTCString();
	    }
	    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
	}
	function getCookie(name) {
	    var nameEQ = name + "=";
	    var ca = document.cookie.split(';');
	    for(var i=0;i < ca.length;i++) {
	        var c = ca[i];
	        while (c.charAt(0)==' ') c = c.substring(1,c.length);
	        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	    }
	    return null;
	}
	
	
	function eraseCookie(name) {   
	    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}

	
	/**
 * watchAwaitSelector
 * * Uses mutation observer to watch for 'selector' involved in DOM changes (added, removed, class change, content change, etc)
 * @param {Function} callback A callback function to be run whenever DOM changes are detected, can be anonymous or named
 * @param {String} selector Element(s) to look for involved in DOM changes, you can watch multiple selectors by separating via comma '.class1, .class2, #id3'
 * @param {Node} rootNode Node to watch from, typically document
 * @param {Number} fallbackDelay Interval to be used if the browser does not support MutationObserver, value is in milliseconds (2000 = 2 seconds)
 */
const awaitSelector = (selector, rootNode, fallbackDelay) => new Promise((resolve, reject) => {
  try {
    const root = rootNode || document
    const ObserverClass = MutationObserver || WebKitMutationObserver || null
    const mutationObserverSupported = typeof ObserverClass === 'function'
    let observer
    const stopWatching = () => {
      if (observer) {
        if (mutationObserverSupported) {
          observer.disconnect()
        } else {
          clearInterval(observer)
        }
        observer = null
      }
    }
    const findAndResolveElements = () => {
      const allElements = root.querySelectorAll(selector)
      if (allElements.length === 0) return
      const newElements = []
      const attributeForBypassing = 'data-awaitselector-resolved'
      allElements.forEach((el, i) => {
        if (typeof el[attributeForBypassing] === 'undefined') {
          allElements[i][attributeForBypassing] = ''
          newElements.push(allElements[i])
        }
      })
      if (newElements.length > 0) {
        stopWatching()
        resolve(newElements)
      }
    }
    if (mutationObserverSupported) {
      observer = new ObserverClass(mutationRecords => {
        const nodesWereAdded = mutationRecords.reduce(
          (found, record) => found || (record.addedNodes && record.addedNodes.length > 0),
          false
        )
        if (nodesWereAdded) {
          findAndResolveElements()
        }
      })
      observer.observe(root, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeOldValue: true,
        characterData: true,
        characterDataOldValue: true
      })
    } else {
      observer = setInterval(findAndResolveElements, fallbackDelay || 250)
    }
    findAndResolveElements()
  } catch (exception) {
    reject(exception)
  }
})

const watchAwaitSelector = (callback, selector, rootNode, fallbackDelay) => {
  (function awaiter(continueWatching = true) {
    if (continueWatching === false) return
    awaitSelector(selector, rootNode, fallbackDelay)
      .then(callback)
      .then(awaiter)
  }())
}

function urldecode(str) {
	return decodeURIComponent((str+'').replace(/\+/g, '%20'));
}

const utm_source_param = urlParams.get('utm');
if(utm_source_param && !window.localStorage.getItem('utm_source')) {
	window.localStorage.setItem('utm_source', utm_source_param);
}

function newGetCookie(name) {
	let cookie = {};
	document.cookie.split(';').forEach(function(el) {
	  let [k,v] = el.split('=');
	  cookie[k.trim()] = v;
	})
	return cookie[name];
}


watchAwaitSelector(function(elements) {
	let utm_source = window.localStorage.getItem('utm_source');
	
	let utm_source_inputs = document.querySelectorAll('.user-analytics-utm-source-container input');
	
	if(utm_source) {
		for(let input of utm_source_inputs) {
			jQuery( `#${input.id}` ).val( utm_source ).trigger( 'change' );
		}
	}
	
}, '.user-analytics-utm-source-container input');


watchAwaitSelector(function(elements) {
	let referrer = window.localStorage.getItem('origin_referrer');
	
	let referrer_inputs = document.querySelectorAll('.user-analytics-referer-container input');
	
	if(referrer) {
		for(let input of referrer_inputs) {
			jQuery( `#${input.id}` ).val( referrer ).trigger( 'change' );
		}
	}
	
}, '.user-analytics-referer-container input');

}(jQuery));